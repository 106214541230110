import React, { useCallback } from "react";
import { useSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

export function useNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useCallback(
    (message, variant, actions) => {
      enqueueSnackbar(message, {
        variant: variant || "default",
        action: key => (
          <React.Fragment>
            {actions}
            <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
              <CloseOutlinedIcon />
            </IconButton>
          </React.Fragment>
        )
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );
}

export function useApiNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useCallback(
    (status, configurations, defaultConfig) => {
      let config = defaultConfig;
      if (configurations[status]) {
        config = configurations[status];
      }

      enqueueSnackbar(config.message, {
        variant: config.variant || "default",
        action: key => (
          <React.Fragment>
            {config.actions}
            <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
              <CloseOutlinedIcon />
            </IconButton>
          </React.Fragment>
        )
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );
}
