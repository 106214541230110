import React from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import axios from "axios";
import { retrieveInstructorName } from "../../../utils/app.util";

const RemoveConfirmationDialog = props => {
  const { open, onClose, onBefore, onAfter, onSuccess, entity } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleRemove = () => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity.id);

    const instructorName = retrieveInstructorName(entity.instructor);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Removing class schedule for instructor "${instructorName}"`);
    }, PROGRESS_TIME_LIMIT);

    axios
      .delete(`/api/offeringSchedules/${entity.id}`)
      .then(() => {
        notify(
          `Class schedule for instructor "${instructorName}" removed`,
          "success"
        );

        onSuccess();
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to remove class schedule for instructor "${instructorName}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to remove class schedule for instructor "${instructorName}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity.id);

        clearTimeout(displayProgressTimeoutKey);
      });
  };

  if (!entity) {
    return null;
  }

  const instructorName = retrieveInstructorName(entity.instructor);

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title="Remove Class Schedule?"
      message={
        <React.Fragment>
          Class schedule for instructor <b>{instructorName}</b> is going to be
          removed. You will not be able to recover the data once removed.
        </React.Fragment>
      }
      onConfirm={handleRemove}
    />
  );
};

export default RemoveConfirmationDialog;
