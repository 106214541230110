import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  section: {
    marginTop: theme.spacing(1)
  },
  description: {},
  content: {
    paddingLeft: theme.spacing(3)
  },
  actions: {
    marginTop: theme.spacing(2)
  },
  button: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(2)
  }
}));

const ConfigurationCard = props => {
  const { title, description, sections } = props;

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <div>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
        </div>
        <Divider className={classes.divider} />
        {sections &&
          sections.map(section => {
            const { description, content } = section;

            return (
              <Grid
                container
                alignItems="center"
                className={classes.section}
                key={description}
              >
                <Grid item xs={3} className={classes.description}>
                  <Typography align="right">{description}</Typography>
                </Grid>
                <Grid item xs={9} className={classes.content}>
                  {content}
                </Grid>
              </Grid>
            );
          })}
      </CardContent>
    </Card>
  );
};

export default ConfigurationCard;
