import React from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import AttributeFormDialog, {
  createParams,
  initialValues,
  validationSchema
} from "./AttributeFormDialog";
import _ from "lodash";

const EditForm = props => {
  const { open, onClose, entity, ...rest } = props;

  const { setValues, resetForm } = useFormikContext();

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    const truncatedName = _.truncate(entity.name, {
      length: 30,
      separator: " "
    });

    axios
      .get(`/api/offeringCategories/${entity.id}`)
      .then(response => {
        const receivedValues = response.data;

        normalizeReceivedValues(receivedValues);

        active && resetForm({ values: receivedValues });
      })
      .catch(error => {
        onClose();

        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to class category "${truncatedName}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to fetch user "${truncatedName}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, setValues, resetForm, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <AttributeFormDialog
      {...rest}
      title="Edit Class Category"
      open={open}
      onClose={onClose}
      loading={loading}
    />
  );
};

const FormikEditForm = props => {
  const {
    entity,
    onOpen,
    onClose,
    onSuccess,
    onBefore,
    onAfter,
    ...rest
  } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity);

    const truncatedName = _.truncate(values.name, {
      length: 30,
      separator: " "
    });

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Updating class category "${truncatedName}"`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .put(`/api/offeringCategories/${values.id}`, createParams(values))
      .then(() => {
        notify(`Class category "${truncatedName}" updated`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found for class category "${truncatedName}"`,
              variant: "warning"
            },
            403: {
              message: `Access denied to update class category`,
              variant: "error"
            }
          },
          {
            message: `Unable to update class category "${truncatedName}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity);

        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <EditForm {...rest} entity={entity} onClose={onClose} />
    </Formik>
  );
};

export default FormikEditForm;

const normalizeReceivedValues = receivedValues => {
  receivedValues.name || (receivedValues.name = "");
};
