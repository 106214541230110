import React from "react";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { RECURRING_TYPE_OPTIONS } from "../../../constants/app.constant";
import FormikRadioGroup from "../../../components/inputs/FormikRadioGroup";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikKeyboardTimePicker from "../../../components/inputs/FormikKeyboardTimePicker";
import FormikMonthToggleButtonGroup from "../../../components/inputs/FormikMonthToggleButtonGroup";
import { useFormikContext } from "formik";
import FormikWeekToggleButtonGroup from "../../../components/inputs/FormikWeekToggleButtonGroup";

const RecurringPatternForm = () => {
  const {
    values: { recurringType },
    handleChange,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormikRadioGroup
            required
            name="recurringType"
            label="Class Meets"
            options={RECURRING_TYPE_OPTIONS}
            row
            onChange={event => {
              setFieldTouched("recurringType", true, false);
              setFieldValue("recurringOrders", [], false);
              handleChange(event);
            }}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          {recurringType === "WEEKLY" ? (
            <FormikWeekToggleButtonGroup name="recurringOrders" />
          ) : (
            <FormikMonthToggleButtonGroup name="recurringOrders" />
          )}
        </Grid>
        <Grid item xs={6}>
          <FormikKeyboardDatePicker
            required
            // disablePast
            name="startDate"
            label="Start Date"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikKeyboardDatePicker
            required
            disablePast
            name="endDate"
            label="End Date"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikKeyboardTimePicker
            required
            name="startTime"
            label="Start Time"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikKeyboardTimePicker required name="endTime" label="End Time" />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default RecurringPatternForm;
