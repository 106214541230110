import React from "react";

const ExcelIcon = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path d="M496 80.011H288v-48c0-4.768-2.112-9.28-5.792-12.32-3.648-3.04-8.544-4.352-13.152-3.392l-256 48A15.955 15.955 0 000 80.011v352c0 7.68 5.472 14.304 13.056 15.712l256 48c.96.192 1.952.288 2.944.288 3.712 0 7.328-1.28 10.208-3.68a16.006 16.006 0 005.792-12.32v-48h208c8.832 0 16-7.168 16-16v-320c0-8.832-7.168-16-16-16zM220.032 309.483c5.824 6.624 5.152 16.736-1.504 22.56A15.943 15.943 0 01208 336.011a15.951 15.951 0 01-12.032-5.472l-46.528-53.152-40.8 52.48A16.073 16.073 0 0196 336.011c-3.424 0-6.88-1.088-9.824-3.36-6.976-5.44-8.224-15.488-2.816-22.464l44.608-57.344-44-50.304c-5.824-6.624-5.152-16.736 1.504-22.56 6.624-5.824 16.704-5.184 22.592 1.504L148 227.115l47.392-60.928c5.44-6.944 15.488-8.224 22.464-2.784 6.976 5.408 8.224 15.456 2.784 22.464l-51.2 65.792 50.592 57.824zM480 400.011H288v-32h48c8.832 0 16-7.168 16-16s-7.168-16-16-16h-48v-32h48c8.832 0 16-7.168 16-16s-7.168-16-16-16h-48v-32h48c8.832 0 16-7.168 16-16s-7.168-16-16-16h-48v-32h48c8.832 0 16-7.168 16-16s-7.168-16-16-16h-48v-32h192v288z" />
    <path d="M432 144.011h-32c-8.832 0-16 7.168-16 16s7.168 16 16 16h32c8.832 0 16-7.168 16-16s-7.168-16-16-16zM432 208.011h-32c-8.832 0-16 7.168-16 16s7.168 16 16 16h32c8.832 0 16-7.168 16-16s-7.168-16-16-16zM432 272.011h-32c-8.832 0-16 7.168-16 16s7.168 16 16 16h32c8.832 0 16-7.168 16-16s-7.168-16-16-16zM432 336.011h-32c-8.832 0-16 7.168-16 16s7.168 16 16 16h32c8.832 0 16-7.168 16-16s-7.168-16-16-16z" />
  </svg>
);

export default ExcelIcon;
