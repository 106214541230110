import React from "react";

const LockIcon = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path d="M230.792 354.313l-6.729 60.51a10.67 10.67 0 0010.604 11.844h42.667a10.67 10.67 0 0010.604-11.844l-6.729-60.51c10.927-7.948 17.458-20.521 17.458-34.313 0-23.531-19.135-42.667-42.667-42.667S213.333 296.469 213.333 320c0 13.792 6.532 26.365 17.459 34.313zM256 298.667c11.76 0 21.333 9.573 21.333 21.333 0 8.177-4.646 15.5-12.125 19.125a10.673 10.673 0 00-5.958 10.781l6.167 55.427h-18.833l6.167-55.427c.5-4.49-1.885-8.802-5.958-10.781-7.479-3.625-12.125-10.948-12.125-19.125-.001-11.76 9.572-21.333 21.332-21.333z" />
    <path d="M437.333 192h-32v-42.667C405.333 66.99 338.344 0 256 0S106.667 66.99 106.667 149.333V192h-32A10.66 10.66 0 0064 202.667v266.667C64 492.865 83.135 512 106.667 512h298.667C428.865 512 448 492.865 448 469.333V202.667A10.66 10.66 0 00437.333 192zM128 149.333c0-70.583 57.417-128 128-128s128 57.417 128 128V192h-21.333v-42.667c0-58.813-47.854-106.667-106.667-106.667S149.333 90.521 149.333 149.333V192H128v-42.667zm213.333 0V192H170.667v-42.667C170.667 102.281 208.948 64 256 64s85.333 38.281 85.333 85.333zm85.334 320c0 11.76-9.573 21.333-21.333 21.333H106.667c-11.76 0-21.333-9.573-21.333-21.333v-256h341.333v256z" />
  </svg>
);

export default LockIcon;
