import { Formik, useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import React from "react";
import FilterFormButtonGroup from "../../../components/filters/FilterFormButtonGroup";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormikPackagePlanSelect from "../../../components/inputs/FormikPackagePlanSelect";

const FilterForm = props => {
  const { loading } = props;

  const { handleSubmit, handleReset } = useFormikContext();

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={6}>
            <FormikPackagePlanSelect
              fullWidth
              name="packagePlan"
              label="Package Plan"
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FilterFormButtonGroup loading={loading} align="left" />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </form>
  );
};

export default function(props) {
  const { loading, onSubmit, onReset } = props;

  return (
    <Formik
      initialValues={{
        packagePlan: null
      }}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      <FilterForm loading={loading} />
    </Formik>
  );
}
