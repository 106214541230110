import React from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  order: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main
  },
  section: {
    maxWidth: 320
  },
  form: {
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: "100%"
  }
}));

export default function WizardStepCard(props) {
  const { order, title, children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container alignItems="stretch" spacing={1}>
        <Grid item md={4} sm={4} xs={12} className={classes.section}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Avatar className={classes.order}>{order}</Avatar>
            </Grid>
            <Grid item>
              <Typography variant="h6">{title}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} sm={8} xs={12} className={classes.form}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
}
