import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Label from "../../../components/Label";
import Grid from "@material-ui/core/Grid";
import PercentageCircle from "../../../components/PercentageCircle";
import IconSubTitle from "../../../components/IconSubTitle";
import Paper from "@material-ui/core/Paper";
import React from "react";
import moment from "moment";
import numeral from "numeral";
import { retrieveInstructorName, startTimeEndTimeComparator } from "../../../utils/app.util";
import FaceIcon from "@material-ui/icons/Face";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3)
  },
  table: {
    whiteSpace: "nowrap",
    minWidth: 600
  },
  timeCell: {
    borderRight: `solid 1px ${theme.palette.divider}`,
    width: 200
  },
  time: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  timeHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(1)
  },
  timeText: {
    marginRight: theme.spacing(1)
  },
  offering: {
    display: "flex",
    flexDirection: "row"
  },
  instructor: {
    display: "flex",
    alignItems: "center"
  }
}));

const ScheduleSessionTable = props => {
  const { day, sessions, onEdit, editFormDialog: EditFormDialog } = props;

  const classes = useStyles();

  const theme = useTheme();

  const dayMoment = moment(day);

  sessions.sort(startTimeEndTimeComparator);

  return (
    <Paper className={classes.paper}>
      <PerfectScrollbar>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography variant="h6">{dayMoment.format("dddd, DD MMMM YYYY")}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.map(session => {
              const { startTime, endTime, offering, instructor, onlineCapacity, bookedCount, cancelledCount } = session;

              const startTimeMoment = moment(startTime, "HH:mm");
              const endTimeMoment = moment(endTime, "HH:mm");

              const startTimeText = startTimeMoment.format("HH:mm");
              const endTimeText = endTimeMoment.format("HH:mm");

              const instructorName = retrieveInstructorName(instructor);

              const duration = numeral(endTimeMoment.diff(startTimeMoment, "hours", true)).format("0.0");

              return (
                <TableRow key={`${startTimeText} ${endTimeText} ${instructorName}`}>
                  <TableCell className={classes.timeCell}>
                    <div className={classes.time}>
                      <div className={classes.timeHeader}>
                        <Typography className={classes.timeText} variant="h3">
                          {startTimeText}
                        </Typography>
                        <Label variant="outlined" shape="rounded" color={theme.palette.primary.main}>
                          {dayMoment.format("ddd")}
                        </Label>
                      </div>
                      <Typography variant="body2">End time: {endTimeText}</Typography>
                      <Typography variant="body2">
                        Duration: {duration} hour{duration >= 2 ? "s" : ""}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <PercentageCircle
                          radius={theme.spacing(4)}
                          borderWidth={theme.spacing(0.5)}
                          percent={bookedCount-cancelledCount > onlineCapacity? 100 :(100 * bookedCount) / onlineCapacity}
                          color={theme.palette.secondary.main}>
                          <Typography variant="body1">
                            {bookedCount-cancelledCount}/{onlineCapacity}
                          </Typography>
                        </PercentageCircle>
                      </Grid>
                      <Grid item>
                        <div>
                          <Typography variant="h5" gutterBottom>
                            {offering.name}
                          </Typography>
                          <IconSubTitle
                            config={[
                              {
                                icon: FaceIcon,
                                description: instructorName
                              }
                            ]}
                          />
                        </div>
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Paper>
  );
};

export default React.memo(ScheduleSessionTable);
