import React from "react";
import ListingTable from "./components/ListingTable";
import DisplayDialog from "./components/DisplayDialog";
import RemoveConfirmationDialog from "./components/RemoveConfirmationDialog";
import FilterForm from "./components/FilterForm";
import CreateFormDialog from "./components/CreateFormDialog";
import EditFormDialog from "./components/EditFormDialog";
import ManagementPageHeader from "./components/ManagementPageHeader";
import SubEntityManagement from "../../components/SubEntityManagement";
import axios from "axios";
import { BOOLEAN_OPTIONS } from "../../constants/app.constant";
import moment from "moment";

const OfferingScheduleSessionManagement = props => {
  const {
    match: {
      params: { id }
    },
    location: { state }
  } = props;

  const filterDayAfter = state ? moment(state.specificDay) : null;
  const filterDayBefore = state ? moment(state.specificDay) : null;

  return (
    <SubEntityManagement
      pageHeader={ManagementPageHeader}
      filterForm={FilterForm}
      filterFormInitialValues={{
        includePast: BOOLEAN_OPTIONS[1],
        dayAfter: filterDayAfter,
        dayBefore: filterDayBefore,
        status: null
      }}
      createFormDialog={CreateFormDialog}
      editFormDialog={EditFormDialog}
      displayDialog={DisplayDialog}
      removeConfirmationDialog={RemoveConfirmationDialog}
      listingTable={ListingTable}
      onFilter={values => ({
        ...values,
        includePast: values.includePast && values.includePast.value,
        dayAfter: values.dayAfter && values.dayAfter.format("YYYY-MM-DD"),
        dayBefore: values.dayBefore && values.dayBefore.format("YYYY-MM-DD"),
        status: values.status && values.status.value
      })}
      request={params => {
        return axios.get("/api/offeringScheduleSessions", {
          params: {
            ...params,
            offeringSchedule: id
          }
        });
      }}
      parentApi={`/api/offeringSchedules/${id}`}
    />
  );
};

export default OfferingScheduleSessionManagement;
