import React from "react";
import ListingTable from "./components/ListingTable";
import CreateFormDialog from "./components/CreateFormDialog";
import EditPackageFormDialog from "./components/EditPackageFormDialog";
import RemoveConfirmationDialog from "./components/RemoveConfirmationDialog";
import EditFormDialog from "./components/EditFormDialog";
import ManagementPageHeader from "./components/ManagementPageHeader";
import SubEntityManagement from "../../components/SubEntityManagement";
import axios from "axios";

const OfferingScheduleSessionTranscriptManagement = props => {
  const {
    match: {
      params: { id }
    }
  } = props;

  return (
    <SubEntityManagement
      pageHeader={ManagementPageHeader}
      createFormDialog={CreateFormDialog}
      removeConfirmationDialog={RemoveConfirmationDialog}
      editFormDialog={EditFormDialog}
      editPackageFormDialog={EditPackageFormDialog}
      listingTable={ListingTable}
      request={params => {
        return axios.get(`/api/transcripts/resources`, {
          params: {
            ...params,
            offeringScheduleSession: id
          }
        });
      }}
      parentApi={`/api/effectiveOfferingScheduleSessions/${id}`}
    />
  );
};

export default OfferingScheduleSessionTranscriptManagement;
