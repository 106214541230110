import React from "react";
import ListingTable from "./components/ListingTable";
import DisplayDialog from "./components/DisplayDialog";
import RemoveConfirmationDialog from "./components/RemoveConfirmationDialog";
import FilterForm from "./components/FilterForm";
import CreateFormDialog from "./components/CreateFormDialog";
import EditFormDialog from "./components/EditFormDialog";
import EntityManagement from "../../components/EntityManagement";
import ManagementPageHeader from "./components/ManagementPageHeader";

const StaffUserManagement = () => {
  return (
    <EntityManagement
      pageHeader={ManagementPageHeader}
      filterForm={FilterForm}
      createFormDialog={CreateFormDialog}
      editFormDialog={EditFormDialog}
      displayDialog={DisplayDialog}
      removeConfirmationDialog={RemoveConfirmationDialog}
      listingTable={ListingTable}
      onFilter={values => ({
        ...values,
        instructor: values.instructor && values.instructor.value
      })}
      api="/api/staffUsers"
    />
  );
};

export default StaffUserManagement;
