import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const styles = {
  circle: {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#e3e3e3",
    "& $text": {
      fontSize: 11,
      color: "#888"
    }
  },
  leftWrap: {
    overflow: "hidden",
    position: "absolute",
    top: 0
  },
  rightWrap: {
    overflow: "hidden",
    position: "absolute",
    top: 0
  },
  loader: {
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: 1000,
    transformOrigin: "0 50%"
  },
  loader2: {
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: 1000,
    transformOrigin: "100% 50%"
  },
  innerCircle: {
    position: "relative",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  text: {}
};

class PercentageCircle extends Component {
  constructor(props) {
    super(props);

    const { percent, borderWidth } = props;

    let leftTransformerDegree = "0deg";
    let rightTransformerDegree = "0deg";

    if (percent >= 50) {
      rightTransformerDegree = "180deg";
      leftTransformerDegree = (percent - 50) * 3.6 + "deg";
    } else {
      rightTransformerDegree = percent * 3.6 + "deg";
      leftTransformerDegree = "0deg";
    }

    this.state = {
      percent: percent,
      borderWidth: borderWidth < 2 || !borderWidth ? 2 : borderWidth,
      leftTransformerDegree: leftTransformerDegree,
      rightTransformerDegree: rightTransformerDegree
    };
  }

  render() {
    const {
      classes,
      radius,
      bgColor,
      color,
      borderWidth,
      innerColor,
      children,
      textClassName,
      percent
    } = this.props;

    return (
      <div
        className={classes.circle}
        style={{
          width: radius * 2,
          height: radius * 2,
          borderRadius: radius,
          backgroundColor: bgColor
        }}
      >
        <div
          className={classes.leftWrap}
          style={{
            width: radius,
            height: radius * 2,
            left: 0
          }}
        >
          <div
            className={classes.loader}
            id="id1"
            style={{
              left: radius,
              width: radius,
              height: radius * 2,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: color,
              transform: "rotate(" + this.state.leftTransformerDegree + ")"
            }}
          />
        </div>
        <div
          className={classes.rightWrap}
          style={{
            width: radius,
            height: radius * 2,
            left: radius
          }}
        >
          <div
            className={classes.loader2}
            id="id2"
            style={{
              left: -radius,
              width: radius,
              height: radius * 2,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: color,
              transform: "rotate(" + this.state.rightTransformerDegree + ")"
            }}
          />
        </div>
        <div
          className={classes.innerCircle}
          style={{
            left: borderWidth,
            top: borderWidth,
            width: (radius - borderWidth) * 2,
            height: (radius - borderWidth) * 2,
            borderRadius: radius - borderWidth,
            backgroundColor: innerColor
          }}
        >
          {children ? (
            children
          ) : (
            <Typography variant='inherit' className={textClassName}>{percent}%</Typography>
          )}
        </div>
      </div>
    );
  }
}

PercentageCircle.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  innerColor: PropTypes.string,
  radius: PropTypes.number,
  percent: PropTypes.number,
  borderWidth: PropTypes.number,
  textClassName: PropTypes.string
};

PercentageCircle.defaultProps = {
  color: "#000",
  radius: 20,
  percent: 0,
  borderWidth: 2,
  bgColor: "#e3e3e3",
  innerColor: "#fff",
  disabled: false,
  textClassName: ""
};

export default withStyles(styles)(PercentageCircle);
