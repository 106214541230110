import FormikTextField from "../../../components/inputs/FormikTextField";
import React from "react";
import ConfigurationCard from "./ConfigurationCard";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { SYSTEM_CONFIG_NAMES } from "../SystemConfiguration";

const useStyles = makeStyles(theme => ({
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  intBlank: {
    width: 100,
    marginRight: theme.spacing(1)
  }
}));

const EnrollmentTimeConfiguration = () => {
  const classes = useStyles();

  return (
    <ConfigurationCard
      title="Enrollment Configuration"
      description="Configurations for all time offsets related to enrollment policies."
      sections={[
        {
          description: "Enrollment start time (before class start time)",
          content: (
            <div className={classes.content}>
              <FormikTextField
                required
                className={classes.intBlank}
                name={SYSTEM_CONFIG_NAMES.ENROLLMENT_START_OFFSET}
                variant="outlined"
                margin="dense"
              />
              <Typography>Days</Typography>
            </div>
          )
        },
        {
          description: "Enrollment end time (before class start time)",
          content: (
            <div className={classes.content}>
              <FormikTextField
                required
                className={classes.intBlank}
                name={SYSTEM_CONFIG_NAMES.ENROLLMENT_END_OFFSET}
                variant="outlined"
                margin="dense"
              />
              <Typography>Minutes</Typography>
            </div>
          )
        },
        {
          description: "Cancellation close time (before class start time)",
          content: (
            <div className={classes.content}>
              <FormikTextField
                required
                className={classes.intBlank}
                name={SYSTEM_CONFIG_NAMES.CANCELLATION_CLOSE_OFFSET}
                variant="outlined"
                margin="dense"
              />
              <Typography>Minutes</Typography>
            </div>
          )
        },
        {
          description: "Wait list lock time (before class start time)",
          content: (
            <div className={classes.content}>
              <FormikTextField
                required
                className={classes.intBlank}
                name={SYSTEM_CONFIG_NAMES.WAIT_LIST_LOCK_OFFSET}
                variant="outlined"
                margin="dense"
              />
              <Typography>Minutes</Typography>
            </div>
          )
        },
        {
          description:
            "Time for clients in wait list to confirm once a vacancy is available for them",
          content: (
            <div className={classes.content}>
              <FormikTextField
                required
                className={classes.intBlank}
                name={SYSTEM_CONFIG_NAMES.WAIT_LIST_CONFIRM_WINDOW}
                variant="outlined"
                margin="dense"
              />
              <Typography>Hours</Typography>
            </div>
          )
        }
      ]}
    />
  );
};

export default EnrollmentTimeConfiguration;
