import React from "react";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikKeyboardTimePicker from "../../../components/inputs/FormikKeyboardTimePicker";
import FormikInstructorSelect from "../../../components/inputs/FormikInstructorSelect";
import FormikAutocomplete from "../../../components/inputs/FormikAutocomplete";
import {OFFERING_SCHEDULE_SESSION_STATUS_OPTIONS} from "../../../constants/app.constant";
import axios from "axios";

export default function AttributeFormDialog(props) {
  const {title, open, loading, onClose, showStatus, entity, ...rest} = props;

    axios
        .get(`/api/transcripts/resources`)
        .then(response => {
          
        });
  return (
    <FormikFormDialog
      {...rest}
      title={title}
      open={open}
      onClose={onClose}
      loading={loading}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormikKeyboardDatePicker
              disablePast
              name="day"
              label="Which Day"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikInstructorSelect
              name="instructor"
              label={entity.id}
              fullWidth
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <FormikKeyboardTimePicker name="startTime" label="Start Time"/>
          </Grid>
          <Grid item xs={6}>
            <FormikKeyboardTimePicker name="endTime" label="End Time"/>
          </Grid>
          {showStatus && (
            <Grid item xs={6}>
              <FormikAutocomplete
                name="status"
                margin="dense"
                autoHighlight
                options={OFFERING_SCHEDULE_SESSION_STATUS_OPTIONS}
                getOptionLabel={option => option && (option.label || "")}
                renderOption={option => option && (option.display || "")}
                TextFieldProps={{
                  label: "Status",
                  variant: "outlined",
                  fullWidth: true
                }}
              />
            </Grid>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({});

export const validate = values => {
  const errors = {};

  if (values.startTime && values.endTime) {
    if (values.endTime.isSameOrBefore(values.startTime)) {
      errors.endTime = "Cannot be earlier than or equal to start time";
    }
  }

  return errors;
};

export const initialValues = {
  id: null,
  day: null,
  startTime: null,
  endTime: null,
  instructor: null,
  status: null
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.instructor && params.append("instructor", values.instructor.id);
  values.day && params.append("day", values.day.format("YYYY-MM-DD"));
  values.startTime &&
  params.append("startTime", values.startTime.format("HH:mm"));
  values.endTime && params.append("endTime", values.endTime.format("HH:mm"));

  values.status && params.append("status", values.status.value);

  return params;
};
