import React from "react";
import UnexpectedStatusPage from "./UnexpectedStatusPage";
import ProblemIcon from "../icons/ProblemIcon";

const LoadErrorPage = props => {
  return (
    <UnexpectedStatusPage
      {...props}
      variant="error"
      iconComponent={ProblemIcon}
      message="Failed to load information from server"
    />
  );
};

export default LoadErrorPage;
