import React from "react";
import FormikTextField from "./FormikTextField";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  }
}));

export default function(props) {
  const { rows = "5", ...rest } = props;

  const classes = useStyles();

  return (
    <FormikTextField className={classes.root} multiline rows={rows} {...rest} />
  );
}
