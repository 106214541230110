import { Button, makeStyles } from "@material-ui/core";
import Frame from "../../components/Frame";
import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import EnrollmentTimeConfiguration from "./components/EnrollmentTimeConfiguration";
import axios from "axios";
import ErrorFilterResultPlaceholder from "../../components/filters/ErrorFilterResultPlaceholder";
import LoadingState from "../../components/LoadingState";
import { Formik, useFormikContext } from "formik";
import {
  useApiNotification,
  useNotification
} from "../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../config/app.config";
import getAllFieldErrors from "../../utils/api.util";
import * as Yup from "yup";

export const SYSTEM_CONFIG_NAMES = {
  ENROLLMENT_START_OFFSET: "ENROLLMENT_START_OFFSET",
  ENROLLMENT_END_OFFSET: "ENROLLMENT_END_OFFSET",
  CANCELLATION_CLOSE_OFFSET: "CANCELLATION_CLOSE_OFFSET",
  WAIT_LIST_LOCK_OFFSET: "WAIT_LIST_LOCK_OFFSET",
  WAIT_LIST_CONFIRM_WINDOW: "WAIT_LIST_CONFIRM_WINDOW"
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  form: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column"
  },
  emptyFilterResult: {
    flex: "1 1 auto"
  },
  errorFilterResult: {
    flex: "1 1 auto"
  },
  progress: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0)
  }
}));

const SystemConfiguration = () => {
  const classes = useStyles();

  const {
    resetForm,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty
  } = useFormikContext();

  const [loadingStatus, setLoadingStatus] = useState({
    loading: true,
    error: false
  });

  useEffect(() => {
    let active = true;

    setLoadingStatus({
      loading: true,
      error: false
    });

    axios
      .get("/api/systemConfig")
      .then(response => {
        if (active) {
          const configs = response.data || [];

          const initialValues = getInitialValues(configs);

          resetForm({ values: initialValues });

          setLoadingStatus({
            loading: false,
            error: false
          });
        }
      })
      .catch(() => {
        active &&
          setLoadingStatus({
            loading: false,
            error: true
          });
      });

    return () => {
      active = false;
    };
  }, [resetForm]);

  return (
    <Frame className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <PageHeader
          section="Administration"
          title="System Configuration"
          right={
            <React.Fragment>
              <Button
                className={classes.button}
                variant="contained"
                type="submit"
                color="secondary"
                disabled={isSubmitting || !isValid || !dirty}
              >
                Update
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="default"
                onClick={() => resetForm()}
                disabled={isSubmitting || (!dirty && isValid)}
              >
                Reset
              </Button>
            </React.Fragment>
          }
        />
        {loadingStatus.error ? (
          <ErrorFilterResultPlaceholder className={classes.errorFilterResult} />
        ) : loadingStatus.loading ? (
          <div className={classes.progress}>
            <LoadingState />
          </div>
        ) : (
          <EnrollmentTimeConfiguration />
        )}
      </form>
    </Frame>
  );
};

export default () => {
  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Updating system configuration`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .put(`/api/systemConfig`, createParams(values))
      .then(() => {
        notify("System configuration updated", "success");

        formikActions.resetForm({ values: { ...values } });
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found`,
              variant: "warning"
            },
            403: {
              message: `Access denied to update system configuration`,
              variant: "error"
            }
          },
          {
            message: "Unable to update system configuration",
            variant: "error"
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={getInitialValues([])}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <SystemConfiguration />
    </Formik>
  );
};

export const validationSchema = Yup.object().shape({
  [SYSTEM_CONFIG_NAMES.ENROLLMENT_START_OFFSET]: Yup.number()
    .min(0, "Cannot be smaller than 0")
    .required("Required"),
  [SYSTEM_CONFIG_NAMES.ENROLLMENT_END_OFFSET]: Yup.number()
    .min(0, "Cannot be smaller than 0")
    .required("Required"),
  [SYSTEM_CONFIG_NAMES.CANCELLATION_CLOSE_OFFSET]: Yup.number()
    .min(0, "Cannot be smaller than 0")
    .required("Required"),
  [SYSTEM_CONFIG_NAMES.WAIT_LIST_LOCK_OFFSET]: Yup.number()
    .min(0, "Cannot be smaller than 0")
    .required("Required"),
  [SYSTEM_CONFIG_NAMES.WAIT_LIST_CONFIRM_WINDOW]: Yup.number()
    .min(0, "Cannot be smaller than 0")
    .required("Required")
});

const getInitialValues = configs => {
  const initialValues = {};

  configs.forEach(config => {
    initialValues[config.name] = config.content || "";
  });

  return initialValues;
};

export const createParams = values => {
  const params = new URLSearchParams();

  Object.keys(values).forEach((config, index) => {
    params.append(`config[${index}].name`, config);
    params.append(`config[${index}].content`, values[config]);
  });

  return params;
};
