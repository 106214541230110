import React, { useCallback, useState } from "react";
import axios from "axios";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import BaseEditFormDialog from "./BaseEditFormDialog";
import { retrieveInstructorName } from "../../../utils/app.util";

const EditPackageForm = props => {
  const { open, onOpen, onClose, onSuccess, entity, parent, ...rest } = props;

  const [customerUserInfo, setCustomerUserInfo] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchSwitch, setSearchSwitch] = useState(false);

  const [
    originalSelectedPackage,
    setOriginalSelectedPackage
  ] = useState(null);

  const [
    selectedPurchasedPackagePlan,
    setSelectedPurchasedPackagePlan
  ] = useState(null);

  const handleSearchTextChange = useCallback(searchText => {
    setSearchText(searchText);
  }, []);

  const handleSearchSubmit = useCallback(searchText => {
    setCustomerUserInfo(searchText);
    setSearchSwitch(prevState => !prevState);
  }, []);

  const [loading, setLoading] = useState(false);

  const [candidates, setCandidates] = useState([]);

  const [selectedCustomerUser, setSelectedCustomerUser] = useState(null);

  const handleCustomerUserSelect = useCallback(customerUser => {
    setSelectedCustomerUser(customerUser);
  }, []);

  const [loadingEntity, setLoadingEntity] = useState(false);

  if(entity && !loadingEntity){
    setCustomerUserInfo(entity.customerUser.username);
    setSelectedCustomerUser(null);
    setSearchSwitch(prevState => !prevState);
    setLoadingEntity(true);
  }

  const notifyApiError = useApiNotification();

  React.useEffect(() => {

    if (!customerUserInfo || !entity) {
      return;
    }

    let active = true;

    setLoading(true);

    setCandidates([]);
    // setSelectedCustomerUser(null);
    setSelectedCustomerUser(entity.customerUser);
    setPurchasedPackagePlans([]);
    setSelectedPurchasedPackagePlan(null);
    setOriginalSelectedPackage(null);

    axios
      .get(`/api/customerUsers/actions/search`, {
        params: {
          username: customerUserInfo,
          generatedId: customerUserInfo,
          firstName: customerUserInfo,
          lastName: customerUserInfo,
          middleName: customerUserInfo,
          nickName: customerUserInfo,
          email: customerUserInfo,
          mobilePhone: customerUserInfo
        }
      })
      .then(response => {
        active && setCandidates(response.data);
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to search clients`,
              variant: "error"
            }
          },
          {
            message: `Unable to get search results`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [customerUserInfo, notifyApiError, searchSwitch]);

  const [purchasedPackagePlans, setPurchasedPackagePlans] = useState([]);

  const handlePurchasedPackagePlanSelect = useCallback(purchasedPackagePlan => {
    setSelectedPurchasedPackagePlan(purchasedPackagePlan);
    
    if(originalSelectedPackage === purchasedPackagePlan){
      setOriginalSelectedPackage(purchasedPackagePlan);
    } 
  }, [originalSelectedPackage]);

  React.useEffect(() => {
    if (!selectedCustomerUser || !parent || !entity) {
      return;
    }

    let active = true;

    setLoading(true);
    setSelectedCustomerUser(entity.customerUser);

    setPurchasedPackagePlans([]);
    setSelectedPurchasedPackagePlan(null);

    axios
      .get(`/api/purchasedPackagePlans/actions/queryAvailable`, {
        params: {
        //   customerUser: selectedCustomerUser.id,
          customerUser: entity.customerUser.id,
          offeringScheduleSession: parent.id
        }
      })
      .then(response => {
        var regeneratedPurchasedPackagePlans = [];
        for(var i = 0; i < response.data.length; i++){
          for(var j = 0; j < response.data[i].packageType.length; j++){
            var temp = {};
            var effectiveDate = response.data[i].effectiveDate;
            var expirationDate = response.data[i].expirationDate;
            var packagePlanId = response.data[i].id;
            var packagePlan = response.data[i].packagePlan;
            var status = response.data[i].status;
            var type = response.data[i].packageType[j];
            var exchange = response.data[i].exchangeRate[j];
            var id = response.data[i].purchasedPackagePlanItemId[j];
            var offeringCategoryId = response.data[i].offeringCategoryId[j];
            temp = {"effectiveDate": effectiveDate, "expirationDate": expirationDate, "packagePlanId": packagePlanId, "packagePlan": packagePlan, "status": status, "type": type, "exchange": exchange, "id": id, "offeringCategoryId": offeringCategoryId};
            regeneratedPurchasedPackagePlans.push(temp);
          }
        }
        active && setPurchasedPackagePlans(regeneratedPurchasedPackagePlans);
        setSelectedPurchasedPackagePlan(entity.purchasedPackagePlanItemId);
        setOriginalSelectedPackage(entity.purchasedPackagePlanItemId);
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to obtain package plans`,
              variant: "error"
            }
          },
          {
            message: `Unable to get package plans`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [notifyApiError, parent, selectedCustomerUser, entity]);

  const resetForm = useCallback(() => {
    // setSearchText("");
    // setCustomerUserInfo("");
    // setCandidates([]);
    // setSelectedCustomerUser(null);
    // setPurchasedPackagePlans([]);
    setSelectedPurchasedPackagePlan(originalSelectedPackage);
  }, [originalSelectedPackage]);

  const notify = useNotification();

  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    onClose();

    setSubmitting(true);

    const customerUserName = retrieveInstructorName(entity.customerUser);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Updating client "${customerUserName}"`);
    }, PROGRESS_TIME_LIMIT);

    const params = new URLSearchParams();
    var purchasedPackagePlan;
    var exchangeRate;
    var offeringCategoryId;

    for(var i = 0; i < purchasedPackagePlans.length; i++) {
      if(selectedPurchasedPackagePlan == purchasedPackagePlans[i].id) {
        purchasedPackagePlan = purchasedPackagePlans[i].packagePlanId;
        exchangeRate = purchasedPackagePlans[i].exchange;
        offeringCategoryId = purchasedPackagePlans[i].offeringCategoryId;
      }
    }
    params.append("customerUser", selectedCustomerUser.id);
    params.append("offeringScheduleSession", parent.id);
    params.append("purchasedPackagePlan", purchasedPackagePlan);
    params.append("attended", entity.attended);
    params.append("purchasedPackagePlanItemId", selectedPurchasedPackagePlan);
    params.append("exchangeRate", exchangeRate);
    params.append("enrolledCategoryId", offeringCategoryId);
    params.append("storeBranch", parent.storeBranch.id);

    return axios
      .put(`/api/transcripts/actions/updatePackage/${entity.id}`, params)
      .then((response) => {
        notify(
          `Class scheduled for instructor "${customerUserName}"`,
          "success"
        );

        setOriginalSelectedPackage(selectedPurchasedPackagePlan);
        
        // resetForm();

        onSuccess();
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found`,
              variant: "warning"
            },
            403: {
              message: `Access denied to update clients`,
              variant: "error"
            }
          },
          {
            message: `Unable to update client "${customerUserName}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);

        setSubmitting(false);
      });
  };

  const dirty = false;

  const isDefault =
    selectedPurchasedPackagePlan === originalSelectedPackage;

  const isValid = 
       selectedPurchasedPackagePlan !== originalSelectedPackage || !isDefault;


  return (
    <BaseEditFormDialog
      {...rest}
      title="Update package plans"
      open={open}
      onClose={onClose}
      loading={loading}
    //   onSearchSubmit={handleSearchSubmit}
    //   onCustomerUserSelect={handleCustomerUserSelect}
    //   candidates={candidates}
    //   selectedCustomerUser={selectedCustomerUser}
      purchasedPackagePlans={purchasedPackagePlans}
      onPurchasedPackagePlanSelect={handlePurchasedPackagePlanSelect}
      selectedPurchasedPackagePlan={selectedPurchasedPackagePlan}
      onSubmit={handleSubmit}
      onReset={resetForm}
      dirty={dirty}
      isValid={isValid}
      isSubmitting={isSubmitting}
    //   searchText={searchText}
    //   onSearchTextChange={handleSearchTextChange}
    />
  );
};

export default EditPackageForm;
