import moment from "moment";

export function extractSelectOption(options, key, value) {
  if (value) {
    for (let i = 0; i < options.length; i++) {
      if (options[i][key] === value) {
        return options[i];
      }
    }
  }

  return null;
}

export function retrieveInstructorName(instructor) {
  return instructor && (instructor.firstName || instructor.lastName)
    ? instructor.firstName
      ? instructor.lastName
        ? `${instructor.firstName} ${instructor.lastName}`
        : instructor.firstName
      : instructor.lastName
    : "";
}

export function startTimeEndTimeComparator(a, b) {
  const momentStartTimeA = moment(a.startTime, "HH:mm");
  const momentStartTimeB = moment(b.startTime, "HH:mm");

  const momentEndTimeA = moment(a.endTime, "HH:mm");
  const momentEndTimeB = moment(b.endTime, "HH:mm");

  if (momentStartTimeA.isBefore(momentStartTimeB)) {
    return -1;
  } else if (momentStartTimeA.isAfter(momentStartTimeB)) {
    return 1;
  }

  if (momentEndTimeA.isBefore(momentEndTimeB)) {
    return -1;
  } else if (momentEndTimeA.isAfter(momentEndTimeB)) {
    return 1;
  }

  return 0;
}

export function dateComparator(a, b) {
  const momentA = moment(a);
  const momentB = moment(b);

  if (momentA.isBefore(momentB)) {
    return -1;
  } else if (momentA.isAfter(momentB)) {
    return 1;
  }

  return 0;
}

export function getCustomerUserName(customerUser) {
  return customerUser && (customerUser.firstName || customerUser.lastName)
    ? customerUser.firstName
      ? customerUser.lastName
        ? `${customerUser.firstName} ${customerUser.lastName}`
        : customerUser.firstName
      : customerUser.lastName
    : "";
}
