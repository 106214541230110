import React from "react";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import axios from "axios";
import getAllFieldErrors from "../../../utils/api.util";
import { Formik } from "formik";
import moment from "moment";
import fileDownload from "js-file-download";
import { makeStyles } from "@material-ui/core";
import EmptyEnabledFormikFormDialog from "../../../components/dialogs/EmptyEnabledFormikFormDialog";

const useStyles = makeStyles({
  form: {
    width: 400
  }
});

const PackagePlanBalanceReportConfigFormDialog = props => {
  const { open, onClose, ...rest } = props;

  const classes = useStyles();

  return (
    <EmptyEnabledFormikFormDialog
      {...rest}
      paperClassName={classes.form}
      title="Account Balance Report"
      open={open}
      onClose={onClose}
      loading={false}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <FormikKeyboardDatePicker name="endDate" label="Statement Date" />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </EmptyEnabledFormikFormDialog>
  );
};

const FormikPackagePlanBalanceReportConfigFormDialog = props => {
  const { onOpen, onClose, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    onClose();

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Running account balance report`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .get("/api/reports/purchasedPackagePlanBalances", {
        params: {
          endDate: values.endDate
            ? moment(values.endDate).format("YYYY-MM-DD")
            : undefined
        },
        responseType: "blob"
      })
      .then(response => {
        notify(`Account balance report completed`, "success");

        formikActions.resetForm();

        fileDownload(response.data, "account-balance-report.xls");
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found`,
              variant: "warning"
            },
            403: {
              message: `Access denied to run report`,
              variant: "error"
            }
          },
          {
            message: `Unable to run report`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <PackagePlanBalanceReportConfigFormDialog {...rest} onClose={onClose} />
    </Formik>
  );
};

export default FormikPackagePlanBalanceReportConfigFormDialog;

const initialValues = {
  endDate: null
};
