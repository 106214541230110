import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import PageHeader from "../../../components/PageHeader";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import { OFFERING_MANAGEMENT_PATH } from "../../../constants/route.constant";
import IconSubTitle from "../../../components/IconSubTitle";
import ClassOutlinedIcon from "@material-ui/icons/ClassOutlined";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import { retrieveInstructorName } from "../../../utils/app.util";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import moment from "moment";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import EditFormDialog from "../../OfferingScheduleManagement/components/EditFormDialog";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

const useStyles = makeStyles(theme => ({
  editButton: {
    marginLeft: theme.spacing(2)
  },
  right: {
    minWidth: 240,
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const ManagementPageHeader = props => {
  const { onCreate, parent } = props;

  const classes = useStyles();

  const {
    isCreateOfferingScheduleAllowed,
    isEditOfferingScheduleAllowed
  } = useAccessControl();

  /*
  Form dialog for edit
   */

  const [editFormDialogState, setEditFormDialogState] = useState({
    open: false
  });

  const handleEditFormDialogOpen = React.useCallback(() => {
    setEditFormDialogState(prevState => ({
      ...prevState,
      open: true
    }));
  }, []);

  const handleEditFormDialogClose = React.useCallback(() => {
    setEditFormDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const [editing, setEditing] = useState(false);

  const editFormDialog = (
    <EditFormDialog
      {...editFormDialogState}
      onOpen={handleEditFormDialogOpen}
      onClose={handleEditFormDialogClose}
      onSuccess={() => {
        setEditFormDialogState(prevState => ({
          ...prevState,
          entity: null
        }));
      }}
      onBefore={() => {
        setEditing(true);
      }}
      onAfter={() => {
        setEditing(false);
      }}
    />
  );

  const handleEdit = entity => {
    setEditFormDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  return (
    <React.Fragment>
      <PageHeader
        section="Class Management"
        title="Schedule Sessions"
        right={
          <React.Fragment>
            {isCreateOfferingScheduleAllowed() ? (
              <Button color="secondary" variant="contained" onClick={onCreate}>
                Add Session
              </Button>
            ) : null}
            {isEditOfferingScheduleAllowed() ? (
              <Button
                color="secondary"
                className={classes.editButton}
                startIcon={<EditOutlinedIcon />}
                variant="outlined"
                onClick={() => handleEdit(parent)}
                disabled={editing}
              >
                Edit
              </Button>
            ) : null}
          </React.Fragment>
        }
        rightClassName={classes.right}
        breadcrumbs={[
          {
            name: "Classes",
            link: OFFERING_MANAGEMENT_PATH
          },
          {
            name: "Schedules",
            link: `/offerings/${parent && parent.offering.id}/offeringSchedules`
          }
        ]}
        subTitle={
          parent && (
            <IconSubTitle
              config={[
                {
                  icon: ClassOutlinedIcon,
                  description: parent.offering.name
                },
                {
                  icon: FaceOutlinedIcon,
                  description: retrieveInstructorName(parent.instructor)
                },
                {
                  icon: DateRangeOutlinedIcon,
                  description: `${moment(parent.startDate).format(
                    "YYYY-MM-DD"
                  )} - ${moment(parent.endDate).format("YYYY-MM-DD")}`
                },
                {
                  icon: ScheduleOutlinedIcon,
                  description: `${moment(parent.startTime, "HH:mm").format(
                    "HH:mm"
                  )} - ${moment(parent.endTime, "HH:mm").format("HH:mm")}`
                },
                {
                  icon: GroupOutlinedIcon,
                  description: `${parent.onlineCapacity}`
                },
                {
                  icon: HourglassEmptyOutlinedIcon,
                  description: parent.waitListCapacity
                },
                {
                  icon: LocationOnOutlinedIcon,
                  description: parent.storeBranch && parent.storeBranch.name
                }
              ]}
            />
          )
        }
      />
      {editFormDialog}
    </React.Fragment>
  );
};

export default ManagementPageHeader;
