import React from "react";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";

const CapacityForm = () => {
  return (
    <Grid container spacing={2}>
      {/*<Grid item md={6} xs={12}>*/}
      {/*  <FormikTextField*/}
      {/*    name="capacity"*/}
      {/*    label="Total Capacity"*/}
      {/*    fullWidth*/}
      {/*    margin="dense"*/}
      {/*    variant="outlined"*/}
      {/*  />*/}
      {/*</Grid>*/}
      <Grid item md={6} xs={12}>
        <FormikTextField
          name="onlineCapacity"
          label="Online Capacity"
          fullWidth
          margin="dense"
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormikTextField
          name="waitListCapacity"
          label="Wait List Capacity"
          fullWidth
          margin="dense"
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export default CapacityForm;
