import React from "react";
import { matchPath, withRouter } from "react-router";
import NavSection from "./components/NavSection";
import { useAccessControl } from "../../hooks/accessControl.hook";

function Navigation(props) {
  const { location, className, LeafNodeProps } = props;

  const config = [...props.config];

  const { isAccessGranted } = useAccessControl();

  // Preprocess the config data to retrieve more info for nav rendering
  config.forEach(section => {
    section.items.forEach(tree => processNavTree(tree));
  });

  function processNavTree(node) {
    if (!node.children) {
      // Leaf node
      // Check if the node matches the current path
      node.isOpen = matchPath(location.pathname, {
        path: node.href,
        exact: false
      });

      // Check if access is denied
      node.accessDenied = !isAccessGranted(node.accessControls);
    } else {
      node.children.forEach(child => processNavTree(child));

      if (
        node.children.reduce((isOpen, child) => isOpen || child.isOpen, false)
      ) {
        node.isOpen = true;
      }
    }
  }

  return (
    <nav className={className}>
      {config.map(section => {
        // If all items are access denied, just do not display this section
        if (
          section.items.reduce(
            (accessDenied, item) => accessDenied && item.accessDenied,
            true
          )
        ) {
          return null;
        }

        return (
          <NavSection
            key={section.title}
            section={section}
            LeafNodeProps={LeafNodeProps}
          />
        );
      })}
    </nav>
  );
}

export default withRouter(Navigation);
