import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import { useApiNotification } from "../../../hooks/notification.hook";
import axios from "axios";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import { retrieveInstructorName } from "../../../utils/app.util";
import { OFFERING_SCHEDULE_SESSION_STATUS_VALUE_DISPLAY_MAPPING } from "../../../constants/app.constant";

const DisplayDialog = props => {
  const { open, onClose, entity, ...rest } = props;

  const [detailedEntity, setDetailedEntity] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/offeringScheduleSessions/${entity.id}`)
      .then(response => {
        active && setDetailedEntity(response.data);
      })
      .catch(error => {
        onClose();

        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to fetch session "${entity.day.format(
                "YYYY-MM-DD"
              )}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to fetch session "${entity.day.format(
              "YYYY-MM-DD"
            )}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <LoadableDialog
      {...rest}
      open={open}
      onClose={onClose}
      loading={loading}
      title="Schedule Session Information"
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Day</TableCell>
            <TableCell>
              {detailedEntity.scheduledDay &&
                moment(detailedEntity.scheduledDay).format("YYYY-MM-DD")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Rescheduled Day</TableCell>
            <TableCell>
              {detailedEntity.day &&
                moment(detailedEntity.day).format("YYYY-MM-DD")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Rescheduled Start Time</TableCell>
            <TableCell>
              {detailedEntity.startTime &&
                moment(detailedEntity.startTime, "HH:mm").format("HH:mm")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Rescheduled End Time</TableCell>
            <TableCell>
              {detailedEntity.endTime &&
                moment(detailedEntity.endTime, "HH:mm").format("HH:mm")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Instructor</TableCell>
            <TableCell>
              {detailedEntity.instructor &&
                retrieveInstructorName(detailedEntity.instructor)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              {detailedEntity.status &&
                OFFERING_SCHEDULE_SESSION_STATUS_VALUE_DISPLAY_MAPPING[
                  entity.status
                ]}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </LoadableDialog>
  );
};

export default DisplayDialog;
