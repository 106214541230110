import React from "react";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  checkBox: {
    marginRight: theme.spacing(1)
  }
}));

const CheckBoxSelectOption = props => {
  const { selected, name } = props;

  const classes = useStyles();

  const CheckBoxComponent = selected
    ? CheckBoxOutlinedIcon
    : CheckBoxOutlineBlankOutlinedIcon;

  return (
    <React.Fragment>
      <CheckBoxComponent className={classes.checkBox} />
      <Typography variant="inherit">{name}</Typography>
    </React.Fragment>
  );
};

export default CheckBoxSelectOption;
