import React from "react";
import Grid from "@material-ui/core/Grid";
import FormikStoreBranchSelect from "../../../components/inputs/FormikStoreBranchSelect";

const StoreBranchForm = () => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <FormikStoreBranchSelect
          required
          fullWidth
          name="storeBranch"
          label="Branch"
          margin="dense"
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export default StoreBranchForm;
