import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import { useFormikContext } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: theme.spacing(1),
    ...theme.typography.h5
  }
}));

export default function(props) {
  const {
    name,
    label,
    options,
    FormLabelProps,
    row,
    onChange,
    ...rest
  } = props;

  const classes = useStyles();

  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldTouched,
    isSubmitting
  } = useFormikContext();

  const error = Boolean(touched[name] && errors[name]);

  return (
    <FormControl
      component="fieldset"
      {...rest}
      error={error}
      disabled={isSubmitting}
    >
      <FormLabel
        component="legend"
        {...FormLabelProps}
        className={clsx(
          classes.label,
          FormLabelProps && FormLabelProps.className
        )}
      >
        {label}
      </FormLabel>
      <RadioGroup
        name={name}
        value={values[name]}
        onChange={
          onChange ||
          (event => {
            setFieldTouched(name, true, false);
            handleChange(event);
          })
        }
        row={row}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value.toString()}
            control={<Radio size="small" />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      {error && (
        <FormHelperText error={error}>
          {touched[name] && errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
}
