import React from "react";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikTextArea from "../../../components/inputs/FormikTextArea";

export default function(props) {
  const { open, onClose, title, loading, ...rest } = props;

  return (
    <FormikFormDialog
      {...rest}
      title={title}
      open={open}
      loading={loading}
      onClose={onClose}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikTextField
              required
              fullWidth
              label="Name"
              name="name"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12}>
            <FormikTextArea
              required
              fullWidth
              name="content"
              label="Content"
              variant="outlined"
              rows="8"
            />
          </Grid>
          <Grid item xs={12}>
            <FormikTextField
              fullWidth
              label="External Link"
              name="externalLink"
              variant="outlined"
              margin="dense"
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(200, "No more than 200 characters long")
    .required("Required"),
  content: Yup.string().required("Required"),
  externalLink: Yup.string().matches(/^https?:\/\/[^/]/, "Must start with [http://] or [https://] and be a valid URL")
});

export const initialValues = {
  id: null,
  name: "",
  content: "",
  externalLink: ""
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.name && params.append("name", values.name);
  values.content && params.append("content", values.content);
  values.externalLink && params.append("externalLink", values.externalLink);

  return params;
};
