import React from "react";
import { useFormikContext } from "formik";
import FormDialog from "./FormDialog";

export default function(props) {
  const {
    dirty,
    isValid,
    isSubmitting,
    handleSubmit,
    resetForm
  } = useFormikContext();

  return (
    <FormDialog
      {...props}
      onSubmit={handleSubmit}
      onReset={resetForm}
      submitButtonDisabled={!isValid || isSubmitting}
      resetButtonDisabled={(!dirty && isValid) || isSubmitting}
    />
  );
}
