import { DAY_OF_WEEK_SHORT_NAME } from "../../../constants/app.constant";

export const recurringDaysAsString = (recurringType, recurringOrders) => {
  if (recurringType === "WEEKLY") {
    return recurringOrders
      .map(order => DAY_OF_WEEK_SHORT_NAME[order - 1])
      .join(", ");
  } else if (recurringType === "MONTHLY") {
    return recurringOrders
      .map(order => (order === 32 ? "End of Month" : order))
      .join(", ");
  }

  return null;
};
